import React from 'react'
import styled from 'styled-components'
import Title from '../title'
import PageSEO from '../page_seo'
import Portable from '../portable'
import Linkify from 'react-linkify';
import Balancer from 'react-wrap-balancer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, EffectFade, Autoplay, Navigation } from "swiper";
import { useStaticQuery, graphql } from "gatsby"

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

const Container = styled.div`
  max-width: calc(1200px - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`

const Article = styled.article`
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  font-family: Catamaran, sans-serif;
  strong, em {
    font-family: Catamaran, sans-serif !important;
  }
  h3 {
    margin-bottom: 42px;
  }
  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  li {
    font-family: Catamaran;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }
`

const Intro = styled.h4`
  text-align: center;
  span {
    font-family: Catamaran, sans-serif;
  }
`

const GalleryContainer = styled.div`
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-family: Catamaran, sans-serif;
  h1,h2,h3,h4,h5,h6,p,li {
    color: #fff;
    font-family: Catamaran, sans-serif;
  }
`

const BodyContainer = styled.div`
  background-color: ${props => props.theme === 'black' ? '#000' : '#fff'};
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  font-family: Catamaran, sans-serif;
  padding-top: 3rem;
  padding-bottom: 4rem;
  h1,h2,h3,h4,h5,h6,p,li {
    color: ${props => props.theme === 'black' ? '#fff' : '#000'};
    font-family: Catamaran, sans-serif;
  }
`

const Slideshow = styled.div`
  max-width: 860px;
  width: 100%;
  border: 1px solid var(--pink);
  margin-left: auto;
  margin-right: auto;
  background-color: #000;
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
`

const Slide = styled.div`
  width: 100%;
  max-width: 860px;
  height: 484px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${props => props.image});
  background-position: center 20%;
  background-size: cover;
`

const Page = (props) => {

  const { title, _rawBody, intro, gallery_images, path } = props;

  const theme = gallery_images && gallery_images.length > 0 ? 'black' : 'white';

  return (
    <>
      <PageSEO path={path} title={title} intro={intro} />
      <Container>
        <Title noborder>{title}</Title>
        {intro &&
          <Intro><Balancer>{intro}</Balancer></Intro>
        }
      </Container>

      {gallery_images && gallery_images.length > 0 &&
        <GalleryContainer>
          <Slideshow>
            <Swiper
              navigation={true}
              spaceBetween={0}
              slidesPerView={1}
              className="pbj-slider"
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              keyboard={{
                enabled: true,
              }}
              modules={[Keyboard, EffectFade, Autoplay, Navigation]}
              effect="fade"
              speed={1000}
              fadeEffect={{
                crossFade: true
              }}>
              {gallery_images.map((s, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Slide image={s.asset.url}>

                    </Slide>
                  </SwiperSlide>
                )
              })}

            </Swiper>

          </Slideshow>
        </GalleryContainer>
      }

      <BodyContainer theme={theme}>
        <Article>
          {_rawBody &&
            <Linkify><Portable blocks={_rawBody} /></Linkify>
          }
        </Article>
      </BodyContainer>
    </>
  )
}

export default Page
