import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Page from "../components/page";

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      intro
      gallery_images {
        asset {
          url
          gatsbyImage(width: 250)
          localFile(width: 250) {
            publicURL
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;

  const page = data && data.page;

  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: "default-page",
        }}
      />
      {page && <Page {...page} path={props.location.pathname} />}
    </div>
  );
};

export default PageTemplate;
